import './efficiency.scss'

import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'

const efficiencyBlocksData = [
  {
    title: 'Save Time & Reduce Distractions',
    text: 'Align corporate reporting with 2024 EU and SEC disclosure requirements.'
  },
  {
    title: 'Mitigate Risk',
    text: 'Reliable benchmarking of ESG performance informs communication strategies that preclude crisis management.'
  },
  {
    title: 'Increase Your Impact',
    text: 'Companies that align business and sustainability goals drive collaborative action and create movements.'
  },
]

const efficiencyLinksData = [
  {
    url: 'https://www.newswire.ca/news-releases/small-businesses-left-out-of-big-contracts-without-esg-reporting-bdc-study-832918908.html',
    name: '[1] BOC, 2022'
  },
  {
    url: 'https://www.wellable.co/blog/employee-engagement-statistics-you-should-know/',
    name: '[2] Gallup, 2022'
  },
  {
    url: 'https://www.ftadviser.com/ftadviser-focus/2022/05/20/esg-conscious-companies-worth-50-more-schroders-finds/',
    name: '[3] Schroders, 2022'
  },
]

const Efficiency = () => {
  const blocks = efficiencyBlocksData.map((blockData, idx) => {
    const { title, text } = blockData

    return (
      <div className="efficiency__block" key={idx}>
        <p className="main-subtitle efficiency__block-title">
          {title}
        </p>
        <p className="main-text efficiency__block-text">
          {text}
        </p>
      </div>
    )
  })

  const links = efficiencyLinksData.map((linkData, idx) => {
    const { url, name } = linkData

    return (
      <a
        className="efficiency__link"
        href={url}
        key={idx}
        target="_blank">
        {name}
      </a>
    )
  })

  return (
    <Section className="efficiency">
      <div className="efficiency__blocks">
        {blocks}
      </div>
      <Button
        className="efficiency__cta"
        href="contact"
      >Learn More
      </Button>
      <div className="efficiency__links">
        {links}
      </div>
    </Section>
  )
}

export default Efficiency