import { Link } from 'react-router-dom'

import './insights.scss'

import { Section } from '../shared/Section'
import { Button } from '../shared/Button'

import { ReactComponent as ArrowRight } from 'src/assets/images/arrow-right.svg'

const Insights = ({ data, sortbar }) => {
  const insightsCards = data.map((cardData, idx) => {
    const { id, image, title, text } = cardData

    return (
      <div className="insights__card" key={idx}>
        <figure className="insights__card-figure">
          <Link className="insights__card-imglink" to={`/insight/${id}`}>
            <img className="insights__card-img" src={image} alt={title} />
          </Link>
          <figcaption className="insights__card-figcaption">
            <Button className="insights__card-btn" href={`/insight/${id}`}>
              <ArrowRight />
            </Button>
          </figcaption>
        </figure>
        <div className="insights__card-info">
          <Link className="main-subtitle insights__card-title" href={`/insight/${id}`}>
            {title}
          </Link>
          <p className="main-text insights__card-text">
            {text}
          </p>
        </div>
      </div>
    )
  })

  return (
    <Section className="insights">
      <div className="insights__caption">
        <h2 className="main-title">
          Insights
        </h2>
        {sortbar
          ? sortbar()
          : (
            <Button className="insights__cta" href="/insights">
              More Resources
            </Button>
          )}
      </div>
      <div className="insights__cards">
        {insightsCards}
      </div>
    </Section>
  )
}

export default Insights