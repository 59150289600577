import { Suspense, lazy } from 'react'

import './hero.scss'

import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'

import heroPoster from 'src/assets/images/hero/hero-bg.jpg'

const HeroVideo = lazy(() => import('./HeroVideo.jsx'))

const Hero = () => {
  return (
    <Section className="hero">
      <Suspense fallback={
        <img
          className="hero__poster"
          src={heroPoster}
          alt="Hero poster" />
      }>
        <HeroVideo />
      </Suspense>
      <div className="hero__info">
        <h1 className="page-title">
          All <span className="colored-text">Planet</span>
        </h1>
        <p className="main-subtitle">
          Sustainability & Net Zero Solutions for Advertising, Marketing & Media
        </p>
        <p className="main-text">
          All Planet is your trusted sustainability ally. Quickly calculate the carbon footprint of your company and campaigns, plot a path to certified net zero, and identify bespoke opportunities to make sustainability
          your competitive advantage.
        </p>
        <Button
          className="hero__cta"
          outlined="light"
          href='contact'>
          Contact Us
        </Button>
      </div>
    </Section >
  )
}

export default Hero