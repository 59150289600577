import './about.scss'

import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'

import teammate1 from 'src/assets/images/team/teammate-1.jpg'
import teammate2 from 'src/assets/images/team/teammate-2.jpg'
import teammate3 from 'src/assets/images/team/teammate-3.jpg'
import teammate4 from 'src/assets/images/team/teammate-4.jpg'

import logo1 from 'src/assets/images/team/logos/logo-1.svg'
import logo2 from 'src/assets/images/team/logos/logo-2.svg'
import logo3 from 'src/assets/images/team/logos/logo-3.svg'

const teamData = [
  {
    image: teammate1,
    name: 'Monty Hudson',
    position: 'Founder/CEO',
    description: 'A digital media and AdTech pioneer, Monty brought digital music, the first podcasts, and audience targeting to market. He has spent 20 years in advertising and a lifetime exploring wild places across our planet.'
  },
  {
    image: teammate2,
    name: 'Julien Gervreau',
    position: 'Partner, Sustainability',
    description: 'Director, Sustainability at Sensiba LLP, Julien has been named an Environmental Leader Top 100 and North Bay Business Journal 40 Under 40 for his work in developing sustainable systems that enhance economic growth.'
  },
  {
    image: teammate3,
    name: 'Sandy Skees',
    position: 'Strategic Advisor',
    description: 'An executive at Porter Novelli and author of the book Purposeful Brands, Sandy brings 30 years of corporate and non-profit consulting experience in ESG, DEI, climate, and communications strategy. She chairs the board of Sustainable Brands.'
  },
  {
    image: teammate4,
    name: 'Nina Eisenman',
    position: 'Strategic Advisor',
    description: 'Nina is VP of ESG Strategy for NASDAQ, responsible for climate policy, ESG milestones & reporting, and stakeholder engagement. Prior to this role, she spent 20 years designing brand strategy as an ad agency CEO.'
  }
]

const About = () => {

  const teamCards = teamData.map((teamItem, idx) => {
    const { image, name, position, description } = teamItem

    return (
      <figure className="team__card" key={idx}>
        <img className="team__card-img" src={image} alt={name} />
        <figcaption className="team__card-figcaption">
          <p className="team__card-name main-text">
            {name}
          </p>
          <p className="team__card-position main-text">
            {position}
          </p>
          <p className="team__card-description">
            {description}
          </p>
        </figcaption>
      </figure>
    )
  })

  return (
    <Section className="about" id="about">
      <h2 className="main-title about__title">
        <span className="colored-text">About</span> Us
      </h2>
      <p className="main-text about__text">
        We believe what’s good for the planet is good for business. Our mission is empowering brands and consumers to collaborate in solving the earth’s most pressing problems. Together, we can transform advertising into a force for good and restore the planet by optimizing our impact on people, planet, and the economy.
      </p>
      <div className="team">
        <h2 className="main-title">
          Our Team
        </h2>
        <div className="team__cards">
          {teamCards}
        </div>
      </div>
      <p className="main-text">
        All Planet PBC is a public benefit corporation and member of 1% For The Planet. We support the Conscious Advertising Network (CAN), AdGreen, Sustainable Brands, Ad Net Zero, and the UN Sustainable Development goals.
      </p>
      <div className="about__actions">
        <Button className="about__cta" href="/contact">
          Let’s Meet
        </Button>
        <div className="about__logos">
          <figure className="about__logo">
            <img className="about__logo-img" src={logo1} alt="Logo" />
          </figure>
          <figure className="about__logo">
            <img className="about__logo-img" src={logo2} alt="Logo" />
          </figure>
          <figure className="about__logo">
            <img className="about__logo-img" src={logo3} alt="Logo" />
          </figure>
        </div>
      </div>
    </Section>
  )
}

export default About