import { Section } from 'src/components/shared/Section'

const ThankYouPage = () => {
  return (
    <>
      <Section className="thank-you-page-container">
        <h2 className="main-title">
          Your request has been received.<br /> Thank you
        </h2>
      </Section>
    </>
  )
}

export default ThankYouPage