import { forwardRef } from 'react'
import Select from 'react-select'

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    borderColor: 'transparent',
    minHeight: 'auto',
    width: '100%',
    boxShadow: 'none',
    cursor: 'inherit',
    ':hover': {
      border: 'none',
      boxShadow: 'none'
    }
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#1e1e1e'
  }),
  indicatorsContainer: (_, state) => ({
    '[class$="indicatorContainer"]': {
      padding: '0',
      marginLeft: '8px',
      color: 'inherit',
      transition: '.3s',
      transform: `rotate(${state.selectProps.menuIsOpen ? '-90deg' : ''})`
    }
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (baseStyles) => ({
    ...baseStyles,
    width: 'auto',
    '@media screen and (max-width: 575px)': {
      left: 0
    }
  }),
  valueContainer: (baseStyles) => ({ ...baseStyles, padding: 0 }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    ':active': {
      backgroundColor: '#00a5ce55'
    },
    backgroundColor: state.isSelected
      ? '#00a5ce'
      : state.isFocused
        ? '#00a5ce22'
        : ''

  })
}

export const CustomSelect = forwardRef(({ className, ...attrs }, ref) => {

  return (
    <Select
      className={`select-box ${className}`.trim()}
      styles={selectStyles}
      ref={ref}
      {...attrs} />
  )
})
