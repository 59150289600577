import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom'

import AppLayer from './layers/AppLayer'

import { ErrorPage } from './pages/ErrorPage/ErrorPage'

import Homepage from 'src/pages/HomePage/Homepage'
import InsightsPage from 'src/pages/InsightsPage/InsightsPage'
import InsightPage from 'src/pages/InsightPage/InsightPage'
import ContactPage from 'src/pages/ContactPage/ContactPage'
import ThankYouPage from 'src/pages/ThankYouPage/ThankYouPage'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<AppLayer />}
      errorElement={<ErrorPage />}>
      <Route index element={<Homepage />} />
      <Route path='insights' element={<InsightsPage />} />
      <Route path='insight/:insightID' element={<InsightPage />} />
      <Route path='contact' element={<ContactPage />} />
      <Route path='thank-you' element={<ThankYouPage />} />
    </Route >
  )
)

const Root = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default Root