import './guideline.scss'

import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'

import guidelineImg from 'src/assets/images/guideline/guideline-img.jpg'

const guidelineData = [
  {
    title: 'Measure',
    text: 'Benchmark the sustainability footprint of your company and operations'
  },
  {
    title: 'Act',
    text: 'Implement your action plan to maximize positive impact on planet, people, and the bottom line'
  },
  {
    title: 'Inspire',
    text: 'Confidently engage stakeholders with passion and purpose'
  },
]

const Guideline = () => {
  const guidelineList = guidelineData.map((guidelineItem, idx) => {
    const { title, text } = guidelineItem

    return (
      <li className="guideline__list-item" key={idx}>
        <p className="main-subtitle guideline__list-item--title">
          {title}
        </p>
        <p className="main-text guideline__list-item--text">
          {text}
        </p>
      </li>
    )
  })

  return (
    <Section className="guideline" containerless>
      <figure className="guideline__figure">
        <img className="guideline__img" src={guidelineImg} alt="Guideline" />
      </figure>
      <div className="guideline__info">
        <h2 className="main-title">
          <span className="colored-text">3 Simple Steps</span> to Sustainability Success
        </h2>
        <ol className="guideline__list">
          {guidelineList}
        </ol>
        <Button href="/contact">
          Start Now
        </Button>
      </div>
    </Section>
  )
}

export default Guideline