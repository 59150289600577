import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'

import 'swiper/css'

import './enlist.scss'

import { Section } from '../shared/Section'

import exactChange from 'src/assets/images/enlist/brands/exact-change.png'
import goodNet from 'src/assets/images/enlist/brands/good-net.png'
import groundedWorld from 'src/assets/images/enlist/brands/grounded-world.png'
import legacy from 'src/assets/images/enlist/brands/legacy.png'
import mediaSense from 'src/assets/images/enlist/brands/media-sense.png'
import publicLogo from 'src/assets/images/enlist/brands/public.png'
import rtm from 'src/assets/images/enlist/brands/rtm.svg'
import sagent from 'src/assets/images/enlist/brands/sagent.png'
import sensiba from 'src/assets/images/enlist/brands/sensiba.png'

const brandImages = [
  {
    img: exactChange,
    name: 'Exact Change'
  },
  {
    img: goodNet,
    name: 'Good Net'
  },
  {
    img: groundedWorld,
    name: 'Grounded World'
  },
  {
    img: legacy,
    name: 'Legacy'
  },
  {
    img: mediaSense,
    name: 'Media Sense'
  },
  {
    img: publicLogo,
    name: 'Public'
  },
  {
    img: rtm,
    name: 'Right Thing Media'
  },
  {
    img: sagent,
    name: 'Sagent'
  },
  {
    img: sensiba,
    name: 'Sensiba'
  }
]

const Enlist = () => {
  const brands = brandImages.map((brand, idx) => {
    const { img, name } = brand

    return (
      <SwiperSlide key={idx}>
        <figure className="brands__item">
          <img className="brands__item-img" src={img} alt={name} />
        </figure>
      </SwiperSlide>
    )
  })

  return (
    <Section className="enlist">
      <h2 className="main-title enlist__title">
        Join the Ad Industry’s Growing Community of Companies Dedicated to Positive Impact
      </h2>
      <Swiper
        className="brands"
        modules={[Autoplay]}
        slidesPerView={4}
        loop={true}
        speed={3000}
        grabCursor={false}
        allowTouchMove={false}
        autoplay={{
          delay: 0
        }}
        breakpoints={{
          320: {
            slidesPerView: 1.5
          },
          575: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          991: {
            slidesPerView: 4
          }
        }}>
        {brands}
      </Swiper>
    </Section>
  )
}

export default Enlist