import { useRef, useState } from 'react'

import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'

import { Section } from 'src/components/shared/Section'
import { FormField } from 'src/components/shared/FormField'
import { CustomSelect } from 'src/components/shared/CustomSelect'

import Enlist from 'src/components/enlist/Enlist'
import {useNavigate} from "react-router-dom";

const formSelectOptions = [
  { value: 'carbon accounting/reduction', label: 'Carbon accounting / reduction' },
  { value: 'climate neutral certification', label: 'Climate neutral certification' },
  { value: 'reporting', label: 'Reporting' },
  { value: 'financial Impacts of Sustainability/ESG', label: 'Financial Impacts of Sustainability / ESG' },
  { value: 'growth Opportunities', label: 'Growth Opportunities' },
  { value: 'other', label: 'Other' },
]

const contactListData = [
  {
    id: 1,
    text: 'Your organization’s carbon footprint (Scope 1, 2, 3)'
  },
  {
    id: 2,
    text: 'Your most relevant ESG issues, metrics, and KPIs'
  },
  {
    id: 3,
    text: 'The relationship between ESG risks, opportunities, and value creation'
  },
  {
    id: 4,
    text: 'How to leverage sustainability reporting to deepen key business relationships'
  },
  {
    id: 5,
    text: 'Your organization’s most impactful next steps'
  },
]

const ContactPage = () => {
  const navigate = useNavigate()
  const form = useRef()

  const sendEmail = () => {

  }

  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)
  const [recaptchaError, setRecaptchaError] = useState({})

  const recaptchaRef = useRef()

  const [visibleSelectValue, setVisibleSelectValue] = useState(true)

  const recaptchaHandler = (response) => {
    if (response) {
      setRecaptchaError({})
    }
  }

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm()

  const submitForm = () => {
    if (recaptchaRef.current.getValue() !== '') {
      emailjs.sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_API_KEY
      ).then(() => {
        reset()
        reset({ topic: '' })
        setVisibleSelectValue(false)
        recaptchaRef.current.reset()
        navigate('/thank-you')
      })
    } else {
      setRecaptchaError({ value: 'recaptcha failed' })
    }
  }

  const contactList = contactListData.map(({ id, text }) => (
    <li className="main-text contact__list-item" key={id}>
      {text}
    </li>
  ))

  return (
    <>
      <Section className="contact">
        <div className="contact__info">
          <h1 className="page-title contact__title">
            <span className="colored-text">Contact</span> Us
          </h1>
          <p className="main-subtitle contact__subtitle">
            <span className="colored-text">Chart your path</span> to sustainability success with a free consultation and baseline report.
          </p>
          <p className="main-subtitle contact__list-title">
            We’ll help you understand:
          </p>
          <ol className="contact__list">
            {contactList}
          </ol>
        </div>
        <form
          className="form"
          ref={form}
          onSubmit={handleSubmit(submitForm)}
          noValidate>
          <FormField
            type="email"
            placeholder="Email"
            register={register}
            label="email"
            isError={errors.email}
            errorMessage="Invalid email"
            validationOpts={{
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            }} />
          <FormField
            name="firstName"
            placeholder="First Name"
            register={register}
            label="firstName"
            isError={errors.firstName}
            errorMessage="Invalid first name"
            validationOpts={{
              minLength: 3
            }} />
          <FormField
            name="lastName"
            placeholder="Last Name"
            register={register}
            label="lastName"
            isError={errors.lastName}
            errorMessage="Invalid last name" />
          <FormField
            name="title"
            register={register}
            label="title"
            placeholder="Title"
            isError={errors.title}
            errorMessage="Invalid title" />
          <label className="form-label">
            <Controller
              control={control}
              name='topic'
              rules={{
                required: true
              }}
              render={({ field }) => (
                <CustomSelect
                  className="select-box form-input"
                  placeholder="Choose a Topic"
                  options={formSelectOptions}
                  isSearchable={false}
                  controlShouldRenderValue={visibleSelectValue}
                  {...field} />
              )} />
            <span
              className={`form-input--error ${errors.topic ? 'visible' : ''}`.trim()}>
              Select a topic
            </span>
          </label>
          <label className="form-label">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={recaptchaRef}
              onChange={recaptchaHandler}
              asyncScriptOnLoad={() => setTimeout(() => setIsRecaptchaLoaded(true), 500)} />
            {
              isRecaptchaLoaded && (
                <span
                  className={`form-input--error ${recaptchaError?.value ? 'visible' : ''}`.trim()}>
                  reCAPTCHA is required
                </span>
              )
            }
          </label>
          <button
            className="main-btn form-submit"
            disabled={!isRecaptchaLoaded}>
            Send
          </button>
        </form>
      </Section>
      <Enlist />
    </>
  )
}

export default ContactPage
