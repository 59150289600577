import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import 'src/assets/styles/style.scss'

import Header from 'src/components/header/Header'
import Footer from 'src/components/footer/Footer'

const AppLayer = () => {
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    })
  }, [location])

  return (
    <>
      <Header headerDark={pathname.includes('/insights') || pathname.includes('/contact')} />

      <main
        className={`main ${pathname !== '/' ? `${pathname.replace(/[^a-zA-Z]/g, '')}-page` : 'homepage'}`}>
        <Outlet />
      </main >

      <Footer />
    </>
  )
}

export default AppLayer