import insightsImg1 from 'src/assets/images/insights/insights-img-1.jpg'
import insightsImg2 from 'src/assets/images/insights/insights-img-2.jpg'
import insightsImg3 from 'src/assets/images/insights/insights-img-3.jpg'
import insightsImg4 from 'src/assets/images/guideline/guideline-img.jpg'
import insightsImg5 from 'src/assets/images/solutions/solutions-img.jpg'
import insightsImg6 from 'src/assets/images/enlist/enlist-img.jpg'

export const insightSectionData = [
  {
    id: 1,
    image: insightsImg1,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 2,
    image: insightsImg2,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 3,
    image: insightsImg3,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  }
]

export const insightPageData = [
  {
    id: 4,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 5,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 6,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 7,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 8,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 9,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 10,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 11,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 12,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 13,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 14,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 15,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 16,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 17,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 18,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 19,
    image: insightsImg6,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 20,
    image: insightsImg4,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 21,
    image: insightsImg5,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  }
]