import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import Root from 'src/App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <Root />
  </StrictMode>
);