export const FormField = (props) => {
  const { type = 'text',
    register,
    label,
    isError,
    errorMessage = 'Incorrect input',
    validationOpts = {},
    ...attrs
  } = props

  return (
    <label className="form-label">
      <input
        className="form-input"
        type={type}
        {...register(label, {
          required: true,
          ...validationOpts
        })}
        {...attrs} />
      <span
        className={`form-input--error ${isError ? 'visible' : ''}`.trim()}>
        {errorMessage}
      </span>
    </label>
  )
}
