import { useRouteError } from 'react-router-dom'

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'

import { Button } from 'src/components/shared/Button'

export const ErrorPage = () => {
  const { status, statusText, error: { message } } = useRouteError()

  return (
    <>
      <Header headerDark />
      <main
        className="main error-page">
        <h1 className="main-title error-page__text">
          <p>{status} | <span className="colored-text">{statusText}</span></p>
          <p className="main-title"><span className="colored-text">Oops!</span> An error occurred</p>
          <p className="main-text">{message}</p>
        </h1>
        <Button
          className="main-btn"
          href="/">
          Go home
        </Button>
      </main >
      <Footer />
    </>
  )
}