import { Link } from 'react-router-dom'

export const Button = (props) => {
  const { className = '', href = '#', outlined, children, ...attrs } = props

  const outlineClass = outlined
    ? outlined === 'light'
      ? 'outlined-light'
      : 'outlined-dark'
    : ''

  return (
    <Link
      className={`main-btn ${className} ${outlineClass}`.trim()}
      to={href}
      {...attrs}>
      {children}
    </Link>
  )
}
