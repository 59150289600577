import { insightSectionData } from 'src/data/insightsData'

import Enlist from 'src/components/enlist/Enlist'
import Insights from 'src/components/insights/Insights'

import Hero from './components/hero/Hero'
import Guideline from './components/guideline/Guideline'
import Solutions from './components/solutions/Solutions'
import Profit from './components/profit/Profit'
import Efficiency from './components/efficiency/Efficiency'
import About from './components/about/About'

const Homepage = () => {
  return (
    <>
      <Hero />
      <Guideline />
      <Solutions />
      <Enlist />
      <Profit />
      <Efficiency />
      <About />
      {/* <Insights data={insightSectionData} /> */}
    </>
  )
}

export default Homepage