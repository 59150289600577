import { useState, useRef, useEffect } from 'react'

import './header.scss'

import { Logo } from '../shared/Logo'
import { Navbar } from '../shared/Navbar'
import { Button } from '../shared/Button'

const Header = ({ headerDark = false }) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false)
  const headerRef = useRef()

  useEffect(() => {
    const setHeaderFixed = () => {
      const headerHeight = headerRef.current.offsetHeight
      setIsHeaderFixed(window.scrollY > headerHeight - 30)
    }

    window.addEventListener('scroll', setHeaderFixed)

    return () => window.removeEventListener('scroll', setHeaderFixed)
  }, [window.scrollY])

  return (
    <header
      className={`header ${headerDark ? 'header-dark' : ''} ${isHeaderFixed ? 'fixed' : ''}`.trim()}
      ref={headerRef}>
      <div className="container">
        <div className="header__body">
          <Logo />
          <Navbar />
          <Button
            className="login__btn"
            href="https://app.allplanet.io/login"
            outlined={headerDark ? 'dark' : 'light'}>
            Log In
          </Button>
        </div>
      </div>
    </header>
  )
}

export default Header