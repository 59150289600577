import { Link } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'src/assets/images/logo.svg'

export const Logo = ({ href = '/' }) => {
  return (
    <Link
      className="logo"
      to={href}>
      <LogoIcon />
    </Link>
  )
}
