import './profit.scss'

import { Section } from 'src/components/shared/Section'

import salesImg from 'src/assets/images/profit/sales.png'
import profitImg from 'src/assets/images/profit/profit.png'
import valuationsImg from 'src/assets/images/profit/valuations.png'

const Profit = () => {
  return (
    <Section className="profit" id="profit">
      <h2 className="main-title">
        The <span className="colored-text">ROI of Environmental and Social Impact</span>
      </h2>
      <div className="profit__cards">
        <div className="profit__card">
          <p className="main-subtitle profit__card-title">
            More Sales
          </p>
          <figure className="profit__card-figure">
            <img className="profit__card-img" src={salesImg} alt="More Sales" />
          </figure>
          <p className="main-text profit__card-text">
            84% of major advertisers prioritize vendors that disclose sustainability performance (ESG) metrics<sup>[1]</sup>
          </p>
        </div>
        <div className="profit__card">
          <p className="main-subtitle profit__card-title">
            More Profit
          </p>
          <figure className="profit__card-figure">
            <img className="profit__card-img" src={profitImg} alt="More Profit" />
          </figure>
          <p className="main-text profit__card-text">
            Sustainability drives worker engagement and productivity. Companies with high employee engagement are 21% more profitable<sup>[2]</sup>
          </p>
        </div>
        <div className="profit__card">
          <p className="main-subtitle profit__card-title">
            Higher Valuations
          </p>
          <figure className="profit__card-figure">
            <img className="profit__card-img" src={valuationsImg} alt="Higher Valuations" />
          </figure>
          <p className="main-text profit__card-text">
            Sustainable companies drive 52% higher valuations<sup>[3]</sup>
          </p>
        </div>
      </div>
    </Section>
  )
}

export default Profit