import { useState } from 'react'

import './solutions.scss'

import { AccordionItem } from 'src/components/shared/AccordionItem'
import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'

import solutionsImg from 'src/assets/images/solutions/solutions-img.jpg'

const accordionData = [
  {
    title: 'Assessments',
    text: [
      'Benchmark the carbon footprint of your company and operations (Scope 1, 2, and 3)',
      'Quantify the impact of labor, human rights, and DEI initiatives',
      'Rate key impact areas that create value, improve organizational resilience, and provide a competitive edge'
    ]
  },
  {
    title: 'Action Plans',
    text: [
      'Customized to your organization’s goals, needs, and priorities',
      'Set measurable sustainability targets and track progress',
      'Certify achievements, such as carbon neutrality, in accordance with independent, global standards'
    ]
  },
  {
    title: 'Insights & Analytics',
    text: [
      'Gain competitive insights through comparative analysis',
      'Track scope 3 emissions and ESG progress of vendors, suppliers, partners, and competitors',
      'Benchmark the environmental and social footprint of media partners and plans'
    ]
  },
  {
    title: 'Reporting & Communication',
    text: [
      'Share commitments, progress, certifications, and achievements',
      'Mitigate reputational risk by communicating with authenticity',
      'Confidently engage stakeholders with passion and purpose'
    ]
  }
]

const Solutions = () => {
  const [activeAccIdx, setActiveAccIdx] = useState(0)

  const accordionItems = accordionData.map((itemData, idx) => (
    <AccordionItem
      key={idx}
      data={itemData}
      idx={idx}
      activeAccIdx={activeAccIdx}
      setActiveAccIdx={setActiveAccIdx} />
  ))

  return (
    <Section className="solutions" containerless id="solutions">
      <header className="solutions__header">
        <h2 className="main-title solutions__title">
          <span className="colored-text">All Planet Solutions.</span> Align your environmental and social impact with financial goals. Gain foundational insights that create brand value and fuel corporate strategy. Enable agile decision-making from a 360° stakeholder perspective.
        </h2>
      </header>
      <div className="solutions__content">
        <ul
          className="accordion">
          {accordionItems}
        </ul>
        <figure className="solutions__figure">
          <img
            className="solutions__img"
            src={solutionsImg}
            alt="Solutions" />
        </figure>
      </div>
      <footer className="solutions__footer">
        <p className="main-text">
          We have partnered with Sensiba LLP, a leading B-Corp™ accounting, assurance, and consulting firm, to deliver sustainability assessments and solutions. Our carbon accounting, ESG assessments, and sustainability strategies are transparent, auditable, and aligned with global reporting and disclosure frameworks.
        </p>
        <Button href="/contact">
          Receive a Free Consultation
        </Button>
      </footer>
    </Section>
  )
}

export default Solutions