import { useState, useEffect, useRef } from 'react'

export const AccordionItem = ({ data, idx, activeAccIdx, setActiveAccIdx }) => {
  const { title, text } = data

  const contentRef = useRef()
  const [contentHeight, setContentheight] = useState(0)

  const accHandler = (idx) => {
    setActiveAccIdx(prevIdx => prevIdx === idx ? null : idx)
  }

  useEffect(() => {
    setTimeout(() => setContentheight(contentRef.current?.scrollHeight), 300)
  }, [activeAccIdx, contentRef])

  const itemContent = (text) => {
    const itemText = Array.isArray(text) ? text.map((textItem, textIdx) => (
      <li
        className="accordion__item-list--item main-text"
        key={textIdx}>
        {textItem}
      </li>
    )) : text

    return Array.isArray(text) ? (
      <ul className="accordion__item-list">
        {itemText}
      </ul>
    ) : text
  }

  return (
    <li className={`accordion__item ${activeAccIdx === idx ? 'active' : ''}`.trim()} key={idx}>
      <p
        className="main-subtitle accordion__item-title"
        onClick={() => accHandler(idx)}>
        <span>{title}</span>
        <i className="cross">
          <span></span>
          <span></span>
        </i>
      </p>
      <div className="main-text accordion__item-text" style={{
        height: activeAccIdx === idx ? `${contentHeight}px` : 0
      }}
        ref={contentRef}>
        {itemContent(text)}
      </div>
    </li>
  )
}
