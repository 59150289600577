export const Section = ({ className, containerless, children, ...attrs }) => {
  return (
    <section className={`${className} ${containerless ? 'containerless' : ''}`.trim()} {...attrs}>
      {!containerless ? (
        <div className="container">
          <div className={`${className}__body`}>
            {children}
          </div>
        </div>
      ) : (
        <div className={`${className}__body`}>
          {children}
        </div>
      )}
    </section>
  )
}