import './footer.scss'

import { Logo } from '../shared/Logo'
import { Navbar } from '../shared/Navbar'

import { ReactComponent as Twitter } from 'src/assets/images/social/twitter.svg'
import { ReactComponent as Linkedin } from 'src/assets/images/social/linkedin.svg'

const socialData = [
  {
    icon: <Twitter />,
    url: 'https://twitter.com/monty4theplanet'
  },
  {
    icon: <Linkedin />,
    url: 'https://www.linkedin.com/in/montyhudson/recent-activity/all/'
  }
]

const Footer = () => {
  const socialMedia = socialData.map((social, idx) => {
    const { icon, url } = social

    return (
      <a
        className="social__link"
        href={url}
        key={idx}
        target="_blank">
        {icon}
      </a>
    )
  })
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__body">
          <Logo />
          <Navbar />
        </div>
      </div>
      <div className="copy">
        <div className="container">
          <div className="copy__body">
            <p className="main-text copy__text">
              © {new Date().getFullYear()}. All rights reserved.
            </p>
            <div className="social">
              {socialMedia}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer