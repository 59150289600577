import { useParams } from 'react-router-dom'

import { insightSectionData } from 'src/data/insightsData'
import { insightPageData } from 'src/data/insightsData'

import { Section } from 'src/components/shared/Section'

import Insights from 'src/components/insights/Insights'

import insightImg2 from 'src/assets/images/insights/insights-img-2.jpg'
import insightImg3 from 'src/assets/images/insights/insights-img-3.jpg'

import { ReactComponent as TwitterRound } from 'src/assets/images/social/twitter-round.svg'
import { ReactComponent as LinkedinRound } from 'src/assets/images/social/linkedin-round.svg'

const InsightPage = () => {
  const { insightID } = useParams()

  const insightData = [...insightSectionData, ...insightPageData].find(({ id }) => id === +insightID)

  if (!insightData) {
    return (
      <div>Insight is not found</div>
    )
  }

  const { title, text, image } = insightData

  return (
    <>
      <Section className="insight">
        <h1 className="page-title insight__title">
          {title}
        </h1>
        <p className="main-text insight__subtitle">
          {text}
        </p>
        <figure className="insight__figure">
          <img className="insight__figure-img" src={image} alt="Insight" />
        </figure>
        <article className="main-text insight__article">
          <div className="insight__content">
            <p>
              <strong>
                Lorem ipsum dolor sit amet consectetur. Donec est ultricies id libero quis morbi. Ornare eros in venenatis ut tortor egestas cursus sit. Magnis blandit sed nunc id tempor. In amet consequat semper ut sed egestas eu nunc sit. Sem in neque in orci blandit. Elementum quis scelerisque enim fames integer.
              </strong>
            </p>
            <h2>
              <span>Lorem ipsum</span> dolor sit amet consectetur. Volutpat eget vulputate
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Nunc feugiat purus convallis nam enim proin sagittis neque et. Neque et purus nascetur nec ac pellentesque commodo tempor tincidunt. Pellentesque integer etiam proin vestibulum dui rutrum. Mattis tincidunt sed nunc libero. Dolor mauris ultrices sed etiam tristique nec elementum placerat. Magna habitant lectus ut egestas viverra in sagittis nisi. Faucibus adipiscing mattis pellentesque rhoncus malesuada. Etiam at porttitor iaculis risus volutpat imperdiet eget imperdiet. Cursus pulvinar elit sem orci. Id semper sodales tristique ipsum aliquet.
            </p>
            <p>
              Id sed posuere mauris aliquam feugiat turpis id. Id maecenas id quam ut ornare sed. Fringilla semper diam tincidunt velit ut sit diam a. Egestas eleifend leo fermentum commodo quis tempus id. Ultricies lorem eu et non hendrerit arcu ut aenean convallis. Bibendum viverra non ipsum nec dui dictumst viverra tempor. Orci tellus aliquet nulla venenatis tincidunt amet. Adipiscing lorem diam et lacus ac.
            </p>
            <p>
              Fames tristique laoreet scelerisque fringilla egestas id quam maecenas. Dolor iaculis imperdiet eget hac mauris mattis varius odio suspendisse. Sem luctus imperdiet ipsum est sem nunc in tellus nibh. Interdum cursus egestas dictum facilisis orci enim at. Sed sit libero pellentesque hac laoreet vulputate arcu donec venenatis.
            </p>
            <figure>
              <img src={insightImg2} alt="Insight 2" />
            </figure>
            <p>
              Id sed posuere mauris aliquam feugiat turpis id. Id maecenas id quam ut ornare sed. Fringilla semper diam tincidunt velit ut sit diam a. Egestas eleifend leo fermentum commodo quis tempus id. Ultricies lorem eu et non hendrerit arcu ut aenean convallis. Bibendum viverra non ipsum nec dui dictumst viverra tempor. Orci tellus aliquet nulla venenatis tincidunt amet. Adipiscing lorem diam et lacus ac.
            </p>
            <h3>
              Dolor sit amet consectetur volutpat eget vulputate
            </h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Nunc feugiat purus convallis nam enim proin sagittis neque et. Neque et purus nascetur nec ac pellentesque commodo tempor tincidunt. Pellentesque integer etiam proin vestibulum dui rutrum. Mattis tincidunt sed nunc libero. Dolor mauris ultrices sed etiam tristique nec elementum placerat. Magna habitant lectus ut egestas viverra in sagittis nisi. Faucibus adipiscing mattis pellentesque rhoncus malesuada. Etiam at porttitor iaculis risus volutpat imperdiet eget imperdiet. Cursus pulvinar elit sem orci. Id semper sodales tristique ipsum aliquet.
            </p>
            <p>
              Id sed posuere mauris aliquam feugiat turpis id. Id maecenas id quam ut ornare sed. Fringilla semper diam tincidunt velit ut sit diam a. Egestas eleifend leo fermentum commodo quis tempus id. Ultricies lorem eu et non hendrerit arcu ut aenean convallis. Bibendum viverra non ipsum nec dui dictumst viverra tempor. Orci tellus aliquet nulla venenatis tincidunt amet. Adipiscing lorem diam et lacus ac.
            </p>
            <p>
              Fames tristique laoreet scelerisque fringilla egestas id quam maecenas. Dolor iaculis imperdiet eget hac mauris mattis varius odio suspendisse. Sem luctus imperdiet ipsum est sem nunc in tellus nibh. Interdum cursus egestas dictum facilisis orci enim at. Sed sit libero pellentesque hac laoreet vulputate arcu donec venenatis.
            </p>
            <blockquote>
              Sem luctus imperdiet ipsum est sem nunc in tellus nibh. Interdum cursus egestas dictum facilisis orci enim at. Sed sit libero pellentesque hac laoreet vulputate arcu donec.
            </blockquote>
            <p>
              Id sed posuere mauris aliquam feugiat turpis id. Id maecenas id quam ut ornare sed. Fringilla semper diam tincidunt velit ut sit diam a. Egestas eleifend leo fermentum commodo quis tempus id. Ultricies lorem eu et non hendrerit arcu ut aenean convallis. Bibendum viverra non ipsum nec dui dictumst viverra tempor. Orci tellus aliquet nulla venenatis tincidunt amet. Adipiscing lorem diam et lacus ac.
            </p>
            <p>
              Fames tristique laoreet scelerisque fringilla egestas id quam maecenas. Dolor iaculis imperdiet eget hac mauris mattis varius odio suspendisse. Sem luctus imperdiet ipsum est sem nunc in tellus nibh. Interdum cursus egestas dictum facilisis orci enim at. Sed sit libero pellentesque hac laoreet vulputate arcu donec venenatis.
            </p>
            <figure>
              <img src={insightImg3} alt="Insight 3" />
            </figure>
            <h3>
              Dolor sit amet consectetur volutpat eget vulputate
            </h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Nunc feugiat purus convallis nam enim proin sagittis neque et. Neque et purus nascetur nec ac pellentesque commodo tempor tincidunt. Pellentesque integer etiam proin vestibulum dui rutrum. Mattis tincidunt sed nunc libero. Dolor mauris ultrices sed etiam tristique nec elementum placerat. Magna habitant lectus ut egestas viverra in sagittis nisi. Faucibus adipiscing mattis pellentesque rhoncus malesuada. Etiam at porttitor iaculis risus volutpat imperdiet eget imperdiet. Cursus pulvinar elit sem orci. Id semper sodales tristique ipsum aliquet.
            </p>
            <hr />
            <div className="article__socials">
              <p className="article__socials-title">
                Share
              </p>
              <div className="article__socials-links">
                <a
                  className="article__socials-link"
                  href="https://twitter.com/monty4theplanet"
                  target="_blank">
                  <TwitterRound />
                </a>
                <a
                  className="article__socials-link"
                  href="https://www.linkedin.com/in/montyhudson/recent-activity/all/"
                  target="_blank">
                  <LinkedinRound />
                </a>
              </div>
            </div>
          </div>
          <aside className="insight__aside">
            <h2 className="main-subtitle insight__aside-title">
              <span className="colored-text">Most popular</span> insights
            </h2>
            <div className="insight__aside-links">
              <a className="insight__aside-link" href="#">
                Lorem ipsum dolor sit amet consectetur adipiscing elit
              </a>
              <a className="insight__aside-link" href="#">
                Lorem ipsum dolor sit amet con
              </a>
              <a className="insight__aside-link" href="#">
                Lorem ipsum dolor sit amet consectetur
              </a>
              <a className="insight__aside-link" href="#">
                Lorem ipsum dolor sit amet con
              </a>
              <a className="insight__aside-link" href="#">
                Lorem ipsum dolor sit amet consectetur adipiscing elit
              </a>
            </div>
          </aside>
        </article>
      </Section>
      <Insights data={insightSectionData} />
    </>
  )
}

export default InsightPage