import { useState, useEffect, useRef } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const navData = [
  {
    name: 'Solutions',
    url: '#solutions'
  },
  {
    name: 'Why',
    url: '#profit'
  },
  // {
  //   name: 'Insights',
  //   url: 'insights'
  // },
  {
    name: 'About',
    url: '#about'
  },
]

export const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuHandler = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  const handleLink = (evt, link) => {
    const { pathname } = location
    
    setIsMenuOpen(false)

    if (pathname === '/') {
      if (link.startsWith('#') && document.querySelector(link) !== null) {
        evt.preventDefault()
        document.querySelector(link).scrollIntoView()
      }
    } else {
      navigate('/')
      setTimeout(() => {
        document.querySelector(link) && document.querySelector(link).scrollIntoView()
      }, 0)
    }
  }

  const navLinks = navData.map((linkData, idx) => {
    const { name, url } = linkData
    return (
      <li
        className="nav__item"
        key={idx}>
        <NavLink
          className="nav__item-link"
          to={url}
          onClick={(evt) => handleLink(evt, url)}>
          {name}
        </NavLink>
      </li>
    )
  })

  const bodyRef = useRef(document.body)

  useEffect(() => {
    bodyRef.current.classList.toggle('static', isMenuOpen)
  }, [isMenuOpen])

  return (
    <nav className={`nav ${isMenuOpen ? 'menu-open' : ''}`.trim()}>
      <ul className="nav__menu">
        {navLinks}
      </ul>
      <button
        className="nav__burger"
        onClick={menuHandler}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
  )
}