import { useState } from 'react'
import { Link } from 'react-router-dom'

import { insightPageData } from 'src/data/insightsData'

import { Section } from 'src/components/shared/Section'
import { Button } from 'src/components/shared/Button'
import { CustomSelect } from 'src/components/shared/CustomSelect'

import Insights from 'src/components/insights/Insights'

import { ReactComponent as ArrowRight } from 'src/assets/images/arrow-right.svg'
import insightCardImg1 from 'src/assets/images/insights/insights-img-1.jpg'
import insightCardImg2 from 'src/assets/images/insights/insights-img-2.jpg'
import insightCardImg3 from 'src/assets/images/insights/insights-img-3.jpg'

const insightsHeroDara = [
  {
    id: 1,
    isMainCard: true,
    img: insightCardImg1,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 2,
    isMainCard: false,
    img: insightCardImg2,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  },
  {
    id: 3,
    isMainCard: false,
    img: insightCardImg3,
    title: 'Title',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
  }
]

const selectOptions = [
  { value: 'recent', label: 'Recent' },
  { value: 'popularity', label: 'Popularity' },
  { value: 'time', label: 'Time' }
]

const InsightsPage = () => {
  const insightsHeroCards = insightsHeroDara.map((cardData, idx) => {
    const { id, isMainCard, img, title, text } = cardData

    return (
      <div className={`insights__card ${isMainCard ? 'main-card' : ''}`.trim()} key={idx}>
        <figure className="insights__card-figure">
          <Link className="insights__card-imglink" to={`/insight/${id}`}>
            <img className="insights__card-img" src={img} alt={title} />
          </Link>
          {isMainCard && (
            <figcaption className="insights__card-figcaption">
              <Button className="insights__card-btn" href={`/insight/${id}`}>
                <ArrowRight />
              </Button>
            </figcaption>
          )}
        </figure>
        <div className="insights__card-info">
          <Link className="main-subtitle insights__card-title" to={`/insight/${id}`}>
            {title}
          </Link>
          <p className="main-text insights__card-text">
            {text}
          </p>
          {!isMainCard && (
            <Link className="insights__card-link" to={`/insight/${id}`}>
              <span>Read More</span>
              <ArrowRight />
            </Link>
          )}
        </div>
      </div>
    )
  })

  const [visibleInsights, setVisibleInsights] = useState(() => insightPageData.slice(0, 15))

  const loadInsights = (evt) => {
    evt.preventDefault()

    const nextInsights = insightPageData.slice(visibleInsights.length, visibleInsights.length + 3)
    setVisibleInsights(prevInsights => [...prevInsights, ...nextInsights])
  }

  const [openSelect, setOpenSelect] = useState(false)

  return (
    <Section className="insights-hero">
      <h1 className="page-title">
        <span className="colored-text">Feature</span> Insights
      </h1>
      <div className="insights-hero__cards">
        {insightsHeroCards}
      </div>
      <Insights data={visibleInsights} sortbar={() => (
        <div className="select-box" onClick={() => setOpenSelect(!openSelect)}>
          <span>Sort by:</span>
          <CustomSelect
            className="form-input"
            options={selectOptions}
            defaultValue={selectOptions[0]}
            isSearchable={false}
            menuIsOpen={openSelect}
            onMenuOpen={() => setOpenSelect(!openSelect)} />
        </div>
      )} />
      {visibleInsights.length < insightPageData.length && (
        <Button
          className="insights-page__cta"
          outlined="dark"
          onClick={loadInsights}>
          Load more
        </Button>
      )}
    </Section>
  )
}

export default InsightsPage